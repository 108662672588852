import React, { Component } from 'react';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, Upload, Pagination, Tooltip, Alert, Table } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';

const { Option } = Select;

export default class libPostView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            dataSelected: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/lib/post?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    changeFormFilter = (e) => {
        if(this.timeout_data) clearTimeout(this.timeout_data);
        this.timeout_data = setTimeout(() => {
            this.getListData();
        }, typeof e["title"] == "string" ? 600 : 0)
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Thư viện bài viết mẫu: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm mới'}>
                                <a className="btn btn-primary text-white mr-2" ><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                            }}
                            onValuesChange={this.changeFormFilter}
                        >
                            <Form.Item name="group_id" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className="row justify-content-between">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="title">
                                                <Input placeholder="Nhập tên bài đăng" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-1">
                                            <Button htmlType="reset" onClick={() => {
                                                this.getListData({ type: 'reset' });
                                            }} type="primary">Xóa lọc</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table 
                            loading={this.state.loading}
                            dataSource={this.state.listData}
                            columns={[
                                {
                                    title: "#",
                                    render: (item, value, index) => {
                                        return (this.limit * this.page) - this.limit + index + 1
                                    }
                                },
                                {
                                    title: "Tiêu đề",
                                    dataIndex: "title"
                                },
                                {
                                    title: "Nội dung",
                                    dataIndex: "content",
                                    render: (item) => {
                                        return item.length > 100 ? item.slice(0, 100)+'..' : item
                                    }

                                },
                                {
                                    title: "Hình ảnh",
                                    dataIndex: "image",
                                    render: (item) => {
                                        return item ? <img className="img-thumbnail rounded" src={item}/> : null
                                    }
                                }
                            ]}
                            pagination={{
                                total: this.state.total,
                                current: this.page,
                                size: this.limit,
                                onChange: (current, size) => {
                                    this.page = current;
                                    this.limit = size;
                                    this.getListData();
                                }
                            }}
                            rowKey="_id"
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
