import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { message, Spin, notification, Modal} from 'antd'

import Policy from './views/policy';
import { checkIssetLogin } from './config/settings';
import { MainlayoutView, LoginView, RegisterView, RegisterFanpageView } from './views';
import { notifyBrowser } from './lib/helpers';

import configData from './lib/config';

import { fetchData } from './lib/apis';

class AppComponent extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
          UserLogin: null,
          activeTabBrowser: true
        }
        this.notificationMain = this.notificationMain.bind(this);
    }
    componentWillMount = () => {
      let _this = this;
      window.addEventListener('visibilitychange', function(){
        _this.setState({
          activeTabBrowser: !_this.state.activeTabBrowser
        })
      })
    }
    componentDidMount = () => {
      if(!localStorage.getItem("read_noti_m") && checkIssetLogin()){
        setTimeout(() => {
          // this.notificationMain();
        }, 1000)
      }
    }
    notificationMain = () => {
      Modal.info({
        title: 'Thông báo',
        className: 'modal-noti',
        content: (
          <div className="content">
            <p>Hệ thống mới nâng cấp, người dùng dịch vụ <strong>Fanpage</strong> vui lòng cập nhập lại page để tiếp tục sử dụng.</p>
            <p style={{marginBottom: "0px"}}>Xin cảm ơn!</p>
          </div>
        ),
        onOk() {
          localStorage.setItem("read_noti_m", 1);
        },
      });
    }

    notification = (options) => {
      let { type, title, content, key, duration, placement} = options;
      placement = placement ? placement : "topRight";
      type = type ? type : 'success';
      notification[type]({
        message: title,
        description: content,
        key: key,
        duration: duration,
        placement: placement
      })
    }
    message = (options) => {
      let { type, key, content, duration} = options;
      type = type ? type : 'success';
      
      message[type]({
        content: content,
        key: key,
        duration: duration
      })
    }

    copyText = (text) => {
      let el = document.querySelector('#input-copy');
      el.textContent = text;
      el.select();
      document.execCommand("copy");
      this.notification({
          title: 'Copy text',
          content: 'Thao tác thành công!',
          type: "success"
      });
    }

    sleep = (ms) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, ms);
      })
    }

    render () {
      let props_m = {
        notification: this.notification,
        message: this.message,
        copyText: this.copyText,
        sleep: this.sleep,
        notifyBrowser: notifyBrowser,
        activeTabBrowser: this.state.activeTabBrowser,
        configData: configData,
        fetchData: fetchData,
        setting: {
          account_fb: [
            "https://www.facebook.com/263.doanthanhnien"
          ]
        }
      }
      return (
        <Router>
            <textarea id="input-copy" style={{ opacity: 0, position: 'absolute', zIndex: 0, width: "1px", height: "1px", top: "-100px" }} onKeyPress={(e) => { e.preventDefault(); return false }} readOnly />
            <Switch>
                <Route exact path="/login" render={ props => <LoginView {...props} {...props_m} />} />
                {/* <Route exact path="/register" render={ props => <RegisterView {...props} {...props_m} />} /> */}
                <Route
                  exact
                  path='/policy'
                  render={props => <Policy {...props}/>}
                />
                <Route
                  exact
                  path='/fanpage/register'
                  render={props => <RegisterFanpageView {...props} {...props_m}/>}
                />

                <Route
                  path='*'
                  render={ props => <MainlayoutView {...props} {...props_m}/> }
                />

            </Switch>
        </Router>
      )
    }
}
export default AppComponent;
