import React, { Component } from 'react';
import { Form, Input, Select, Button, Checkbox, TreeSelect, Spin } from 'antd';

import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';

export default class TreeSelectCity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }
    componentWillMount() {
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let params = {};

            let res = await this.props.fetchData({
                url: `api/v1/geo/list?${qs.stringify(params)}`
            });

            this.setState({ listData: this.props.parseMenu(res.data), loading: false });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <TreeSelect
                showSearch
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select"
                treeData={this.state.listData}
                loading={this.state.loading}
            />
        )
    }
}
