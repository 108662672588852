import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {dimens, getCookie, setCookie, checkIssetLogin} from '../config/settings';

import { Form, Input, Button, Alert} from 'antd';

import { fetchData } from '../lib/apis';

export default class RegisterView extends Component{
  constructor(props){
    super(props);
    this.state = {
        loading: false
    }
    this.onSubmit = this.onSubmit.bind(this);

    if(checkIssetLogin()){
        this.props.history.push('/');
    }
  }
  onSubmit = async (values) => {
    try{
        this.setState({ loading: true, error: null});
        let response = await fetchData({
            url: 'api/v1/user/register',
            method: 'post',
            data: values
        });
        this.setState({ loading: false }, () => {
            this.props.history.push('/login')
            this.props.notification({
                title: "Đăng ký",
                content: "Đăng ký thành công, bạn có thể đăng nhập ngay bây giờ!",
                type: "success"
            })
        });
    } catch(err){
        this.setState({ loading: false, error: err.message || 'Lỗi đăng nhập' });
    }
  }
  render(){
    return(
        <div id="app-container">
            <div id="login-view" className="tab-login">
                <div id="side-caption-panel" className="caption-panel" style={{paddingRight: "20px", paddingLeft: "20px"}}>
                    <div id="sidepanel-title" className="panel-title">Đăng ký</div>
                </div>
                <Form
                    onFinish={this.onSubmit}
                >
                    {
                        this.state.error ? <Alert message={this.state.error} type="error" style={{marginBottom: "15px"}}/> : null
                    }
                    <div className="d-flex">
                        <Form.Item
                            name="first_name"
                            rules={[{ required: true, message: 'Trường này bắt buộc' }]}
                            style={{width: "50%"}}
                        >
                            <Input size="large" placeholder="Họ"/>
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            rules={[{ required: true, message: 'Trường này bắt buộc' }]}
                            style={{width: "50%"}}
                        >
                            <Input size="large" placeholder="Tên"/>
                        </Form.Item>
                    </div>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
                    >
                        <Input size="large" placeholder="Tên đăng nhập *"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                    >
                        <Input.Password size="large" placeholder="Mật khẩu *"/>
                    </Form.Item>
                    <Form.Item
                        name="password_confirm"
                        rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập lại mật khẩu',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Mật khẩu không khớp'));
                              },
                            })
                        ]}
                    >
                        <Input.Password size="large" placeholder="Nhập lại mật khẩu"/>
                    </Form.Item>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Button htmlType="submit" type="primary" loading={this.state.loading}>Tiếp tục</Button>
                        <Link to="/login">
                            Đăng nhập
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
  }
}
