import React, { Component, useCallback } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Result } from 'antd';

import { fetchData } from '../../lib/apis';

import '../../css/dashboard.css';
import '../../css/font-awesome/css/all.min.css';

import { LoadingComponent, Header } from '../../widgets';

import ItemMenu from './component/item-menu';
import Channel from './channel';

import SystemsUserView from './users';
import MeView from './me';
import AutoPostView from './autopost';
import AutoPostLogView from './autopost/log';
import GroupView from './group';
import Group2View from './group/index2';
import RegisterFanpageView from './register_fanpage';
import AdminUserView from './users';
import StatitiscalView from './statitiscal';
import CommentView from './comment';
import LibPostView from './libpost';

export default class MainAdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount = () => {
    this.getMenu();
  }
  getMenu = async () => {
    try {
      let response = await fetchData({
        url: 'api/v1/menu/list',
      });

      this.setState({
        listDataMenu: response.data
      }, () => {
        setTimeout(() => {
          document.querySelectorAll('.sidebar-menu li').forEach((item) => {
            item.addEventListener("click", function () {
              document.querySelector('.sidebar').classList.remove("show");
            })
          })
        }, 200)
      })

    } catch (err) {
      this.props.setCookie('c_t', '', 0);
      this.props.message({ content: 'Xin lỗi hệ thống tạm thời bị gián đoạn vui lòng thử lại sau!', key: 'message', type: 'warning', duration: 180 });
    }
  }
  handleViewError = (code) => {
    let status = 500;
    let title = 500;
    let des = 'Sorry, the server is wrong.';
    if (code == 401 || code == 403) {
      des = 'Sorry, you are not authorized to access this page.';
      status = 403;
      title = code;
    }
    this.setState({
      viewError: <Result
        status={status}
        title={title}
        subTitle={des}
      />,
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname != window.location.pathname) {
      if (this.state.viewError) this.setState({ viewError: null })
    }
  }
  render() {
    if (!this.state.listDataMenu) return <LoadingComponent />;
    let menuAdmin = [
      <li className="head-menu" key={'menu_admin_1'}>
        <div>Thiết lập hệ thống</div>
      </li>,
      <Link to="/admin/proxy" className={this.props.location.pathname == '/admin/proxy' ? 'active' : ''} key={'menu_proxy'}>
        <li>
          <i className="far fa-cog"></i>
          <span className="text">Proxy</span>
        </li>
      </Link>,
      // <Link to="/admin/statitiscal/channel" className={this.props.location.pathname == '/admin/statitiscal/channel' ? 'active' : ''} key={'menu_admin_statitiscal_channel'}>
      //   <li>
      //     <i className="fal fa-chart-bar"></i>
      //     <span className="text">Thống kê kênh</span>
      //   </li>
      // </Link>
    ];
    let list_menus = [];
    this.state.listDataMenu.forEach((menu, i) => {
      list_menus.push(<ItemMenu item={menu} key={"menu_" + menu.menu_id} active={this.props.location.pathname == menu.link} />);
      if (menu.menu_child) {
        menu.menu_child.forEach((item, i) => {
          list_menus.push(<ItemMenu item={item} key={"menu_child_" + item.menu_id} active={this.props.location.pathname == item.link} />);
        })
      }
    })
    var props_m = {
      handleViewError: this.handleViewError,
      ...this.props
    }
    return (
      <div id="app-container">
        <div id="dashboard">
          <Header {...this.props} UserLogin={this.props.UserLogin} Company={this.props.companySelected} ListCompany={this.props.companys} />
          <div className="dashboard-root">
            <div className="sidebar">
              <ul className="sidebar-menu">
                {list_menus}
                {/* {
                  this.props.UserLogin.level == 99 ?
                    menuAdmin : null
                } */}
                <li className="head-menu">
                  <div>Hệ thống</div>
                </li>
                {
                  this.props.UserLogin.is_admin ? <Link to="/admin/users" className={this.props.location.pathname == '/admin/users' ? 'active' : ''} key={'menu_admin-users'}>
                    <li>
                      <i className="fal fa-users"></i>
                      <span className="text">Quản lý người dùng</span>
                    </li>
                  </Link> : null
                }
                <Link to="/me" className={this.props.location.pathname == '/me' ? 'active' : ''} key={'menu_me'}>
                  <li>
                    <i className="fal fa-user"></i>
                    <span className="text">Cá nhân</span>
                  </li>
                </Link>
                <a href="#" onClick={(e) => this.props.AccountMetaData.logOut()}>
                  <li style={{ borderTop: "1px solid #eef2f5" }}>
                    <i className="fal fa-sign-out"></i>
                    <span className="text">Đăng xuất</span>
                  </li>
                </a>

              </ul>
            </div>
            <div className="dashboard-container">
              {
                this.state.viewError ? <div className="list-connect content-m view-error">
                  {this.state.viewError}
                </div> : <Switch>

                  <Route
                    exact
                    path='/channel/fanpage'
                    render={props => <Channel {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/admin/users'
                    render={props => <SystemsUserView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/me'
                    render={props => <MeView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/post/auto'
                    render={props => <AutoPostView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/post/auto/:id'
                    render={props => <AutoPostLogView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/channel/group_fanpage'
                    render={props => <Group2View {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/admin/fanpage'
                    render={props => <RegisterFanpageView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/admin/user'
                    render={props => <AdminUserView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/'
                    render={props => <Channel {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/statistic'
                    render={props => <StatitiscalView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/comment'
                    render={props => <CommentView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/lib/post'
                    render={props => <LibPostView {...props_m} {...props} />}
                  />

                  <Route
                    path="*"
                    render={props => <div className="empty-content">
                      <img src={require('../../img/background.png')} className="listdata-img" alt="img" />
                    </div>}
                  />
                </Switch>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
