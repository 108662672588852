import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, Tooltip, Table, Select, Tag} from 'antd';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const key_loading = 'loading';

export default class SystemsUserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loadingData: true,
            total: 0,
            roleAction: [],
            listDatacity: []
        }
        this.limit = 20;
        this.page = 1;

        this.timeSearch = null;
    }
    componentWillMount() {
        this.getListData();
        this.getListDataCity();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (option = {}) => {
        try {
            let { type } = option;
            this.setState({ loading: true });
            if (type == 'reset') this.page = 1;
            let params = {
                limit: this.limit,
                page: this.page
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/admin/user/list?${qs.stringify(params)}`
            });
            this.setState({
                listData: res.data,
                total: res.total,
                loading: false,
                roleAction: res.role_action
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Danh sách người dùng",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    remove = (ids) => {
        if (!ids || ids.length <= 0) {
            this.props.notification({
                title: "Xóa nhân viên",
                content: "Chọn ít nhất một đối tượng",
                type: "error"
            })
            return
        }
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            // className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/admin/user/delete/`,
                            method: 'delete',
                            data: { ids: ids }
                        })
                        _this.props.notification({
                            title: "Xóa người dùng",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListData();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa người dùng",
                            content: err.message ? err.message : 'Thao tác thất bại',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    resetPassword = (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn reset mật khẩu cho tài khoản này',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let response = await fetchData({
                            url: `api/v1/admin/user/${id}/reset_pw/`,
                            method: 'put',
                        })
                        _this.props.notification({
                            title: "Reset mật khẩu",
                            content: `Thao tác thành công mật khẩu mới là: ${response.password}`,
                            type: "success"
                        })
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Reset mật khẩu",
                            content: err.message ? err.message : 'Thao tác thất bại',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    update = async (values) => {
        try {
            this.setState({ loadingUpdate: true });

            let res = await this.props.fetchData({
                url: `api/v1/admin/user/${values['_id']}`,
                method: 'put',
                data: values
            });

            this.setState({ loadingUpdate: false, visibleFormRole: false, visibleFormPassword: false});
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập dữ liệu người dùng',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingUpdate: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập dữ liệu người dùng',
                type: 'error'
            })
        }
    }
    create = async (values) => {
        try {
            this.setState({ loadingUpdate: true });

            let res = await this.props.fetchData({
                url: `api/v1/admin/user/create`,
                method: 'post',
                data: values
            });

            this.setState({ loadingUpdate: false, visibleFormCreate: false});
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập dữ liệu người dùng',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingUpdate: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập dữ liệu người dùng',
                type: 'error'
            })
        }
    }

    getListDataCity = async () => {
        try {
            this.setState({ loadingCity: true });

            let params = {
                parent_id: 0
            };

            let res = await this.props.fetchData({
                url: `api/v1/geo/list?${qs.stringify(params)}`
            });

            this.setState({ listDatacity: res.data, loadingCity: false });
        } catch (err) {
            this.setState({ loadingCity: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <Modal
                    title={'Chỉnh sửa quyền'}
                    visible={this.state.visibleFormRole}
                    onCancel={() => this.setState({ visibleFormRole: null })}
                    className={"form-modal"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formUpdateRole = evt}
                        onFinish={this.update}
                        className="form_type_1"
                    >
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="role_action" label="Thêm quyền"
                        >
                            <Select mode="multiple">
                                {
                                    this.state.roleAction.map((role) => {
                                        return <Select.Option value={role.action_name} key={role.action_name}>{role.display_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="role_city_ids" label="Chọn nhóm quản lý"
                        >
                            <Select mode="multiple" showSearch showArrow loading={this.state.loadingCity} placeholder="Chọn nhóm"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    this.state.listDatacity.map((city) => {
                                        return <Select.Option value={city.city_id} key={city._id}>{city.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ textAlign: "right" }}>
                            <Button onClick={() => this.setState({ visibleFormRole: null })} style={{ marginRight: "10px", marginTop: "20px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingUpdate}>
                                Cập nhập
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={'Thay đổi mật khẩu'}
                    visible={this.state.visibleFormPassword}
                    onCancel={() => this.setState({ visibleFormPassword: null })}
                    className={"form-modal"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formUpdatePassword = evt}
                        onFinish={this.update}
                        className="form_type_1"
                    >
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="password" label="Mật khẩu"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item style={{ textAlign: "right" }}>
                            <Button onClick={() => this.setState({ visibleFormPassword: null })} style={{ marginRight: "10px", marginTop: "20px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingUpdate}>
                                Cập nhập
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={'Thêm tài khoản'}
                    visible={this.state.visibleFormCreate}
                    onCancel={() => this.setState({ visibleFormCreate: false })}
                    className={"form-modal"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formCreate = evt}
                        onFinish={this.create}
                        className="form_type_1"
                    >
                        <Form.Item name="username" label="Tên đăng nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="fullname" label="Họ và tên"
                            rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="password" label="Mật khẩu"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input />
                        </Form.Item>
                        <Form.Item name="role_action" label="Quyền"
                        >
                            <Select mode="multiple">
                                {
                                    this.state.roleAction.map((role) => {
                                        return <Select.Option value={role.action_name} key={role.action_name}>{role.display_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="role_city_ids" label="Chọn nhóm quản lý"
                        >
                            <Select mode="multiple" showSearch showArrow loading={this.state.loadingCity} placeholder="Chọn nhóm"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    this.state.listDatacity.map((city) => {
                                        return <Select.Option value={city.city_id} key={city._id}>{city.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ textAlign: "right", marginTop: "20px"}}>
                            <Button onClick={() => this.setState({ visibleFormCreate: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingCreate}>
                                Thêm mới
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý người dùng: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleFormCreate: true })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => this.remove(this.state.dataSelected)}><i className="far fa-trash"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                role: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="fullname">
                                                <Input placeholder="Nhập tên người dùng" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div >
                        <Table
                            columns={[
                                {
                                    title: "STT",
                                    render: (item, value, index) => {
                                        return (this.limit * this.page) - this.limit + index + 1
                                    }
                                },
                                {
                                    title: "Avatar",
                                    index: "avatar",
                                    render: (item, value) => {
                                        return <img className="img-avatar" style={{width: "40px", height: "40px"}} src={item.avatar || "/user.jpg"} onError={(e) => e.target.src = '/user.jpg'}/>
                                    }
                                },
                                {
                                    title: "Username",
                                    index: "username",
                                    dataIndex: "username"
                                },
                                {
                                    title: "Tên",
                                    index: "fullname",
                                    dataIndex: "fullname"
                                },
                                {
                                    title: "Quyền",
                                    index: "role",
                                    render: (item) => {
                                        let role_action = item.role_action.map((item) => item.display_name);
                                        return role_action.join(', ')
                                    }
                                },
                                {
                                    title: "Đơn vị",
                                    index: "group",
                                    render: (item) => {
                                        let groups = item.groups ? item.groups.map((group) => group.name) : []
                                        return <div style={{maxWidth: "200px"}}>{groups.join(', ')}</div>
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    index: "created_time",
                                    render: (item) => {
                                        return moment(item.created_time).format('HH:mm DD-MM-YYYY')
                                    }
                                },
                                {
                                    title: "Hành động",
                                    index: "action",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" onClick={() => this.remove([item._id])}><i className="far fa-trash"></i></Button>
                                            </Tooltip>
                                            <Tooltip title="Sửa quyền">
                                                <Button className="ml-2" size="small" type="primay" onClick={() => this.setState({ visibleFormRole: true }, () => {
                                                    setTimeout(() => {
                                                        this.formUpdateRole.setFieldsValue({
                                                            ...item,
                                                            role_action: item.role_action.map((item) => item.action_name),
                                                            role_city_ids: item.role ? item.role.city_ids : []
                                                        })
                                                    })
                                                })}><i className="far fa-edit"></i></Button>
                                            </Tooltip>
                                            <Tooltip title="Đổi mật khẩu">
                                                <Button className="ml-2" size="small" type="primay" onClick={() => this.setState({ visibleFormPassword: true }, () => {
                                                    setTimeout(() => {
                                                        this.formUpdatePassword.setFieldsValue({
                                                            _id: item._id
                                                        })
                                                    })
                                                })}><i className="far fa-user-lock"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            rowKey="_id"
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
