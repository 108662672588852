import React, { Component } from 'react';
import { Form, Input, Select, Button, Checkbox, Divider} from 'antd';

import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

export default class InputCity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listProvinces: [],
            listDistricts: [],
            listCommunes: [],
            listGrassroots: [],
            loadingCity: false,
            loadingCreated: false
        }
    }
    componentWillMount() {
        this.getListCity();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListCity = async (options = {}) => {
        try {
            let level = options['level'] || 0;
            this.setState({ loadingCity: level });
            let params = {
                parent_id: options['parent_id'] || 0
            };

            if (this.props.type == 2) {
                params = {
                    level: parseInt(level),
                    parent_id: options['parent_id']
                }
            }

            let url = this.props.type == 2 ? `api/v1/group/channel2?${qs.stringify(params)}` : `api/v1/geo/list?${qs.stringify(params)}`;
            if(this.props.typeCreate == "client"){
                url = `api/v1/client/geo/list?${qs.stringify(params)}`;
            }
            let res = await this.props.fetchData({
                url: url
            });

            if (level == 0) {
                this.setState({ loadingCity: false, listProvinces: res.data });
            } else if (level == 1) {
                this.setState({ loadingCity: false, listDistricts: res.data });
            } else if (level == 2) {
                this.setState({ loadingCity: false, listCommunes: res.data });
            } else if (level == 3) {
                this.setState({ loadingCity: false, listGrassroots: res.data });
            } else {
                this.setState({ loadingCity: false });
            }
        } catch (err) {
            this.setState({ loadingCity: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }
    createLocation = async (values) => {
        try {
            // console.log(values);
            // return
            if(values['level'] == 1){
                values['parent_id'] = this.props.getFieldValue("provinces");
            } else if(values['level'] == 2){
                values['parent_id'] = this.props.getFieldValue("districts");
            } else if(values['level'] == 3){
                values['parent_id'] = this.props.getFieldValue("communes");
            }
            if(values['level'] > 0 && !values['parent_id']) throw({ message: "Bạn phải chọn nhóm cấp trên trước"});

            this.setState({ loadingCreated: true });
            let response = await this.props.fetchData({
                url: this.props.typeCreate == "client" ? `api/v1/client/geo/create` : `api/v1/geo/create`,
                method: 'post',
                data: values
            })
            if(values['level'] == 0){
                this.state.listProvinces.push(response.data);
                this.props.setValueFields({ provinces: response.data.city_id, districts: "", communes: "" });
                this.state.listDistricts = [];
                this.state.listCommunes = [];
            } else if(values['level'] == 1){
                this.state.listDistricts.push(response.data);
                this.props.setValueFields({ districts: response.data.city_id, communes: "" });
                this.state.listCommunes = [];
            } else if(values['level'] == 2){
                this.state.listCommunes.push(response.data);
                this.props.setValueFields({ communes: response.data.city_id, grassrootsSelect: ""});
                this.state.listGrassroots = [];
            } else{
                this.state.listGrassroots.push(response.data);
            }
            this.setState({
                loadingCreated: false
            })
            if(this.formCreateProvince) this.formCreateProvince.resetFields();
            if(this.formCreateDistrict) this.formCreateDistrict.resetFields();
            if(this.formCreateCommunes) this.formCreateCommunes.resetFields();


            this.getListCity({ level: values['level'] + 1, parent_id: response.data.city_id})

            this.props.notification({
                content: "Thêm thành công",
                title: 'Thêm dữ liệu',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingCreated: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Thêm dữ liệu',
                type: 'error'
            })
        }
    }
    render() {
        let col = this.props.col || "col-md-3";
        return (
            <div className="row">
                <div className={col}>
                    <Form.Item
                        name="provinces"
                        label={this.props.visibleLabel ? "Cấp Tỉnh/TP" : ""}
                    >
                        <Select
                            showSearch
                            placeholder="Cấp Tỉnh/TP"
                            optionFilterProp="children"
                            filterOption={true}
                            onSelect={(e) => {
                                if (this.props.onSelect) this.props.onSelect(e)
                                this.getListCity({ level: 1, parent_id: e })
                            }}
                            allowClear={this.props.allowClear}
                            onClear={() => {
                                this.props.setValueFields({ districts: undefined, communes: undefined, grassroots: undefined })
                                this.setState({ listDistricts: [], listCommunes: [], listGrassroots: [] });
                            }}
                            onChange={() => {
                                this.props.setValueFields({ districts: undefined, communes: undefined, grassroots: undefined })
                                this.setState({ listDistricts: [], listCommunes: [], listGrassroots: [] });
                            }}
                            dropdownRender={menu => {
                                if(!this.props.addItem) return menu;
                                return <div>
                                    {menu}
                                    <Form
                                        onFinish={this.createLocation}
                                        initialValues={{
                                            level: 0
                                        }}
                                        ref={(evt) => this.formCreateProvince = evt}
                                    >
                                        <Form.Item name="level" noStyle>
                                            <Input type="hidden"/>
                                        </Form.Item>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                            <Form.Item style={{ flex: 'auto', marginRight: "5px"}} name="name">
                                                <Input />
                                            </Form.Item>
                                            <Button htmlType="submit" type="primary" className="d-flex align-items-center" loading={this.state.loadingCreated}>
                                                Thêm mới
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            }}
                            onDropdownVisibleChange={(e) => {
                                if(!e && this.formCreateProvince){
                                    this.formCreateProvince.resetFields();
                                }
                            }}
                        >
                            {this.state.listProvinces.map((item, i) => {
                                return <Option key={i} value={this.props.type == 2 ? item._id : item.city_id}>{item.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className={col}>
                    <Form.Item
                        name="districts"
                        label={this.props.visibleLabel ? "Quận/Huyện" : ""}
                        ref={(evt) => this.district = evt}
                    >
                        <Select
                            showSearch
                            placeholder="Cấp Quận/Huyện"
                            optionFilterProp="children"
                            filterOption={true}
                            loading={this.state.loadingCity == 1}
                            onSelect={(e) => {
                                if (this.props.onSelect) this.props.onSelect(e)
                                this.getListCity({ level: 2, parent_id: e })
                            }}
                            allowClear={this.props.allowClear}
                            onClear={() => {
                                this.props.setValueFields({ communes: undefined, grassroots: undefined })
                                this.setState({ listCommunes: [], listGrassroots: [] });
                            }}
                            onChange={() => {
                                this.props.setValueFields({ communes: undefined, grassroots: undefined })
                                this.setState({ listCommunes: [], listGrassroots: [] });
                            }}
                            dropdownRender={menu => {
                                if(!this.props.addItem) return menu;
                                return <div>
                                    {menu}
                                    <Form
                                        onFinish={this.createLocation}
                                        initialValues={{
                                            level: 1
                                        }}
                                        ref={(evt) => this.formCreateDistrict = evt}
                                    >
                                        <Form.Item name="level" noStyle>
                                            <Input type="hidden"/>
                                        </Form.Item>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                            <Form.Item style={{ flex: 'auto', marginRight: "5px"}} name="name">
                                                <Input />
                                            </Form.Item>
                                            <Button htmlType="submit" type="primary" className="d-flex align-items-center" loading={this.state.loadingCreated}>
                                                Thêm mới
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            }}
                            onDropdownVisibleChange={(e) => {
                                if(!e && this.formCreateDistrict){
                                    this.formCreateDistrict.resetFields();
                                }
                            }}
                        >
                            {this.state.listDistricts.map((item, i) => {
                                return <Option key={i} value={this.props.type == 2 ? item._id : item.city_id}>{item.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className={col}>
                    <Form.Item
                        name="communes"
                        label={this.props.visibleLabel ? "Cấp Xã/Phường" : ""}
                    >
                        <Select
                            showSearch
                            placeholder="Cấp Xã/Phường"
                            optionFilterProp="children"
                            filterOption={true}
                            loading={this.state.loadingCity == 2}
                            onSelect={(e) => {
                                if (this.props.onSelect) this.props.onSelect(e)
                                if (this.props.grassrootsInput) {
                                    this.setState({ visibleGrassroots: true });
                                }
                                this.getListCity({ level: 3, parent_id: e })
                            }}
                            allowClear={this.props.allowClear}
                            onClear={() => {
                                this.props.setValueFields({ grassroots: undefined })
                                this.setState({ listGrassroots: [] });
                            }}
                            onChange={() => {
                                this.props.setValueFields({ grassroots: undefined })
                                this.setState({ listGrassroots: [] });
                            }}
                            dropdownRender={menu => {
                                if(!this.props.addItem) return menu;
                                return <div>
                                    {menu}
                                    <Form
                                        onFinish={this.createLocation}
                                        initialValues={{
                                            level: 2
                                        }}
                                        ref={(evt) => this.formCreateCommunes = evt}
                                    >
                                        <Form.Item name="level" noStyle>
                                            <Input type="hidden"/>
                                        </Form.Item>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                            <Form.Item style={{ flex: 'auto', marginRight: "5px"}} name="name">
                                                <Input />
                                            </Form.Item>
                                            <Button htmlType="submit" type="primary" className="d-flex align-items-center" loading={this.state.loadingCreated}>
                                                Thêm mới
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            }}
                            onDropdownVisibleChange={(e) => {
                                if(!e && this.formCreateCommunes){
                                    this.formCreateCommunes.resetFields();
                                }
                            }}
                        >
                            {this.state.listCommunes.map((item, i) => {
                                return <Option key={i} value={this.props.type == 2 ? item._id : item.city_id}>{item.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </div>
                {
                    this.props.grassrootsInput ?
                        <div className={col}>
                            <Form.Item
                                name="grassroots_text"
                                label={this.props.visibleLabel ? "Cấp cơ sở" : ""}
                            >
                                <Input disabled={!this.state.visibleGrassroots} placeholder="Nhập tên cơ sở mới"/>
                            </Form.Item>
                        </div> : null
                }
                {
                    this.props.grassrootsSelect ?
                        <div className={col}>
                            <Form.Item
                                name="grassroots"
                                label={this.props.visibleLabel ? "Cấp cơ sở" : ""}
                            >
                                <Select
                                    showSearch
                                    placeholder="Cấp cơ sở"
                                    optionFilterProp="children"
                                    filterOption={true}
                                    loading={this.state.loadingCity == 3}
                                    onSelect={(e) => {
                                        if (this.props.onSelect) this.props.onSelect(e)
                                    }}
                                    allowClear={this.props.allowClear}
                                >
                                    {this.state.listGrassroots.map((item, i) => {
                                        return <Option key={i} value={this.props.type == 2 ? item._id : item.city_id}>{item.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </div> : null
                }
                {
                    this.props.checkboxGrassroots ?
                        <div className={"col-md-5"}>
                            <Form.Item onChange={(e) => {
                                this.setState({
                                    visibleGrassroots: true
                                })
                                this.props.handleGrassrootsInput({
                                    grassrootsInput: e.target.checked,
                                    grassrootsSelect: !e.target.checked
                                })
                            }} valuePropName="checked">
                                <Checkbox>Bấm vào đây để điền cấp cơ sở nếu không có sẵn</Checkbox>
                            </Form.Item>
                        </div> : null
                }
            </div>
        )
    }
}
