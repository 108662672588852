import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Input, Select, Button, Checkbox } from 'antd';
import { qs } from 'qs';
import { WidgetInputCity, Header2 } from '../widgets';
import { ReCaptcha } from 'react-recaptcha-google';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';

export default class RegisterFanpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Đăng ký",
            grassrootsInput: false,
            grassrootsSelect: true
        }
    }
    componentWillMount = () => {
        
    }
    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                recaptcha: true
            })
        }, 500)
    }
    resgiter = async (values) => {
        try {
            if (!values['confirm']) throw ({ message: 'Bạn phải xác nhận đã làm đủ bước 1,2' });

            this.setState({ loadingForm: true });

            values['group_id'] = values['grassroots'] || values['communes'] || values['districts'] || values['provinces'];

            if (!values['group_id'] && !values['grassroots_text']) throw ({ message: 'chọn ít nhất 1 nhóm quản lý' });
            if (values['grassroots_text'] && (!values['communes'] || !values['districts'] || !values['provinces'])) throw ({ message: 'Các cấp quản lý không hợp lệ!' });

            let url = `api/v1/channel/register`;
            let method = 'post';

            await this.props.fetchData({
                url: url,
                data: values,
                method: method
            });

            this.setState({ loadingForm: false });

            // this.formH.resetFields();

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'ĐĂNG KÝ THÔNG TIN FANPAGE CƠ SỞ',
                type: 'success'
            })
            this.resetRecapcha();
        } catch (err) {
            console.log(err);
            this.resetRecapcha();
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'ĐĂNG KÝ THÔNG TIN FANPAGE CƠ SỞ',
                type: 'error'
            })
        }
    }
    resetRecapcha = () => {
        // this.formH.resetFields(["g-recaptcha-token"]);
        // this.captchaDemo.reset();
    }
    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Helmet>
                    <title>
                        {this.state.title}
                    </title>
                </Helmet>
                <div id="app-container">
                    <div id="dashboard">
                        <Header2 />
                        <div className="dashboard-root">
                            <div className="dashboard-container">
                                <div className="list-connect content-m">
                                    <div className="list-c" style={{ padding: "30px" }}>
                                        <div className="row justify-content-center">
                                            <div className="col-md-7">
                                                <div className="border-b"><h2>ĐĂNG KÝ THÔNG TIN FANPAGE CƠ SỞ</h2></div>
                                                <Form
                                                    name="basic"
                                                    ref={(evt) => this.formH = evt}
                                                    className="form_type_1"
                                                    onFinish={this.resgiter}
                                                >
                                                    <div>
                                                        <p><b>Cơ sở thực hiện đăng ký thông tin Fanpage đang quản lý theo đúng thứ tự các bước như sau:</b></p>
                                                        <p><b>Bước 1:</b> Cấp quyền "Biên tập viên" cho các tài khoản trong danh sách dưới đây:</p>
                                                        {
                                                            this.props.setting && this.props.setting.account_fb ?
                                                                this.props.setting.account_fb.map((item, i) => {
                                                                    return <p key={i} style={{ paddingLeft: "20px" }}>- Tài khoản {i + 1}: <a href={item} target="_blank">{item}</a></p>
                                                                }) : null
                                                        }
                                                        <p>Để cấp quyền, tại Fanpage chọn mục Cài đặt {'>'} Vai trò trên Trang {'>'} Chỉ định một vai trò mới trên Trang. Nhập tài khoản ở trên, rồi chọn quyền "Biên tập viên" và bấm nút Thêm.</p>
                                                        <p><b>Bước 2:</b> Tham gia vào nhóm Quản trị các fanpage Đoàn - Hội:</p>
                                                        <p style={{ paddingLeft: "20px" }}><a href="https://www.facebook.com/groups/263.doanthanhnien/" target="_blank">https://www.facebook.com/groups/263.doanthanhnien/</a></p>
                                                        <Form.Item name="confirm" valuePropName="checked"
                                                            rules={[{ required: true, message: 'Bạn cần phải xác nhận!' }]}
                                                        >
                                                            <Checkbox>Xác nhận đã làm đủ bước 1,2</Checkbox>
                                                        </Form.Item>
                                                        <p><b>Bước 3:</b> Nhập đầy đủ các thông tin bên dưới:</p>
                                                    </div>
                                                    {/* <Form.Item
                                                        name="code"
                                                        label="Mã xác nhận ( Liên hệ bộ phận quản lý )"
                                                        rules={[{ required: true, message: 'Không được để trống' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item> */}
                                                    <Form.Item
                                                        name="fanpage_name"
                                                        label="Tên fanpage"
                                                        rules={[{ required: true, message: 'Tên fanpage không được để trống' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="fanpage_link"
                                                        label="Link fanpage"
                                                        rules={[{ required: true, message: 'Link fanpage không được để trống' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Nhóm fanpage (Đơn vị quản lý)"
                                                    >
                                                        <WidgetInputCity {...this.props} allowClear
                                                            setValueFields={(values) => this.formH.setFieldsValue(values)}
                                                            col={"col-md-4"} addItem={true}
                                                            getFieldValue={(key) => {
                                                                return this.formH.getFieldValue(key)
                                                            }}
                                                            typeCreate={'client'}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Thông tin người quản lý fanpage"
                                                        rules={[{ required: true }]}
                                                    >
                                                        <div style={{ paddingLeft: "50px" }}>
                                                            <Form.Item name="fullname"
                                                                rules={[{ required: true, message: 'Không được để trống' }]}
                                                            >
                                                                <Input placeholder="Họ và tên" />
                                                            </Form.Item>
                                                            <Form.Item name="user_level" rules={[{ required: true, message: 'Không được để trống' }]}>
                                                                <Input placeholder="Chức vụ" />
                                                            </Form.Item>
                                                            <Form.Item name="phone" rules={[{ required: true, message: 'Không được để trống' }]}>
                                                                <Input placeholder="Số điện thoại" />
                                                            </Form.Item>
                                                            <Form.Item name="account_fb" rules={[{ required: true, message: 'Không được để trống' }]}>
                                                                <Input placeholder="Tài khoản facebook (Điền link)" />
                                                            </Form.Item>
                                                            <Form.Item name="email" rules={[{ required: true, message: 'Không được để trống' }]}>
                                                                <Input placeholder="Địa chỉ email" />
                                                            </Form.Item>
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item name="note" label="Ghi chú">
                                                        <Input.TextArea style={{ minHeight: "150px" }} />
                                                    </Form.Item>

                                                    <Form.Item name="g-recaptcha-token" noStyle>
                                                        <Input type="hidden" />
                                                    </Form.Item>

                                                    {/* {
                                                        this.state.recaptcha ?
                                                        <div style={{marginBottom: "10px"}}>
                                                            <ReCaptcha
                                                                ref={(el) => this.captchaDemo = el}
                                                                size="normal"
                                                                data-theme="dark"
                                                                // sitekey={process.env.REACT_APP_KEY_GOOGLE_RECAPTCHA}
                                                                sitekey=""
                                                                verifyCallback={(token) => {
                                                                    this.formH.setFieldsValue({ "g-recaptcha-token": token })
                                                                }}
                                                            />
                                                        </div> : null
                                                    } */}

                                                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_GOOGLE_RECAPTCHA}>
                                                        <GoogleReCaptcha onVerify={(token) => {
                                                            console.log(token);
                                                            this.formH.setFieldsValue({ "g-recaptcha-token": token })
                                                        }} />
                                                    </GoogleReCaptchaProvider>

                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" loading={this.state.loadingForm}>Gửi thông tin</Button>
                                                    </Form.Item>
                                                </Form>
                                                <div>
                                                    <p className="mb-0">Mọi thắc mắc xin vui lòng liên hệ bộ phận hỗ trợ:</p>
                                                    <p className="mb-0">Nguyễn Cao Nguyên - Trung tâm CNTT Trung ương Đoàn</p>
                                                    <p className="mb-0">Điện thoại: 0825.000.899</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
