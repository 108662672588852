import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Radio, Table, TimePicker, Spin, Popover, Tree, Dropdown, Menu, Pagination, Tooltip, Alert, Upload, Checkbox, TreeSelect } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import qs from 'qs';
import { WidgetInputCity, WidgetTreeSelectCity, WidgetTreeCity } from '../../widgets';

const { Option } = Select;

export default class AutoReplyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            loadingCity: false
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getListDataCity();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await this.props.fetchData({
                url: `api/v1/channel/register?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    remove = async (ids) => {
        let _this = this;
        try {
            if (!ids || ids.length == 0) throw ({ message: "Chọn ít nhất 1 nhóm để xóa" })
            Modal.confirm({
                title: 'Bạn có chắc muốn xóa',
                icon: <ExclamationCircleOutlined />,
                okText: 'Tiếp tục',
                okType: 'danger',
                cancelText: 'Hủy',
                className: "modal-confirm-h",
                onOk() {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let res = await _this.props.fetchData({
                                url: `api/v1/channel/register`,
                                method: 'delete',
                                data: {
                                    ids: ids
                                }
                            });
                            _this.getListData();
                            _this.props.notification({
                                content: 'Thao tác thành công!',
                                title: 'Xóa',
                                type: 'success'
                            })
                            resolve();
                        } catch (err) {
                            _this.props.notification({
                                content: err.message || 'Đã có lỗi xảy ra',
                                title: 'Xóa',
                                type: 'error'
                            })
                            reject();
                        }
                    })
                },
                onCancel() { },
            });
        } catch (err) {
            _this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xóa',
                type: 'error'
            })
        }
    }

    confirm = async (values = {}) => {
        let _this = this;
        try {
            Modal.confirm({
                title: 'Bạn có chắc muốn duyệt? hệ thống sẽ tự update nhóm của đơn duyệt vào fanpage tương ứng trên hệ thống.',
                icon: <ExclamationCircleOutlined />,
                okText: 'Tiếp tục',
                okType: 'danger',
                cancelText: 'Hủy',
                className: "modal-confirm-h",
                onOk() {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let res = await _this.props.fetchData({
                                url: `api/v1/channel/register/${values['_id']}/confirm`,
                                method: 'post',
                                data: {
                                    ...values,
                                    status: 1
                                }
                            });
                            _this.getListData();
                            _this.props.notification({
                                content: 'Thao tác thành công!',
                                title: 'Duyệt đơn đăng ký',
                                type: 'success'
                            })
                            resolve();
                        } catch (err) {
                            _this.props.notification({
                                content: err.message || 'Đã có lỗi xảy ra',
                                title: 'Duyệt đơn đăng ký',
                                type: 'error'
                            })
                            reject();
                        }
                    })
                },
                onCancel() { },
            });
        } catch (err) {
            _this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xóa',
                type: 'error'
            })
        }
    }

    getListDataCity = async () => {
        try {
            this.setState({ loadingCity: true });
            let params = {};

            let res = await this.props.fetchData({
                url: `api/v1/geo/list?${qs.stringify(params)}`
            });

            this.setState({ listDatacity: this.props.parseMenu(res.data), loadingCity: false });
        } catch (err) {
            this.setState({ loadingCity: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <Modal
                    title={'Duyệt đơn đăng ký'}
                    visible={this.state.visibleForm}
                    className={"form-modal"}
                    footer={null}
                    onCancel={() => this.setState({ visibleForm: false }, () => this.formH.resetFields())}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formH = evt}
                        className="form_type_1 form-broadcast"
                        onFinish={this.confirm}
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            name="group_id"
                            noStyle
                        >
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item label="Chọn nhóm">
                            <div style={{maxHeight: "300px", overflow: "auto"}}>
                                <Spin spinning={this.state.loadingCity}>
                                    <Tree.DirectoryTree
                                        treeData={this.state.listDatacity}
                                        showLine={true}
                                        onExpand={(e) => {
                                            this.setState({ expandedKeys: e, autoExpandParent: false })
                                        }}
                                        expandedKeys={!this.state.loadingCity ? this.state.expandedKeys : []}
                                        defaultExpandedKeys={this.state.expandedKeys}
                                        autoExpandParent={this.state.autoExpandParent}
                                        showIcon={false}
                                        style={{ minHeight: "100px" }}
                                        selectedKeys={this.state.selectedKeys}
                                        onSelect={(e) => this.setState({ selectedKeys: e }, () => {
                                            this.formH.setFieldsValue({ group_id: e[0] })
                                        })}
                                    />
                                </Spin>
                            </div>
                        </Form.Item>
                        <div className="list-button">
                            <Button disabled={this.state.loadingForm} htmlType="submit" type="primary" loading={this.state.loadingForm}>
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý đăng ký: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => {
                                    this.remove(this.state.dataSelected)
                                }}><i className="far fa-trash"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: ""
                            }}
                        >
                            <Form.Item name="group_id" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="fanpage_name">
                                        <Input placeholder="Tên fanpage cần tìm" onChange={(e) => {
                                            if (this.timeSearch) clearTimeout(this.timeSearch);
                                            this.timeSearch = setTimeout(() => {
                                                this.getListData({ type: 'reset' });
                                            }, 300);
                                        }} />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item name="status">
                                        <Select onChange={() => this.getListData({ type: 'reset' })}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={0}>Đang chờ</Select.Option>
                                            <Select.Option value={1}>Đã duyệt</Select.Option>
                                            <Select.Option value={2}>Đã hủy</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <WidgetInputCity {...this.props} onSelect={(e) => {
                                        this.formFilter.setFieldsValue({ "group_id": e });
                                        this.getListData({ type: 'reset' });
                                    }} setValueFields={(values) => this.formFilter.setFieldsValue(values)} />
                                </div>
                                <div className="col-md-1">
                                    <Button onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData({ type: 'reset' });
                                    }} type="primary">Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table
                            columns={[
                                {
                                    title: "STT",
                                    render: (item, value, index) => {
                                        return (this.limit * this.page) - this.limit + index + 1
                                    }
                                },
                                {
                                    title: "Tên fanpage",
                                    index: "fanpage_name",
                                    render: (item) => {
                                        return <div style={{ maxWidth: "200px" }}>
                                            <a href={'https://fb.com/' + item.fanpage_id} target="_blank">{item.fanpage_name}</a>
                                        </div>
                                    }
                                },
                                {
                                    title: "Nhóm",
                                    index: "level",
                                    render: (item) => {
                                        return item.group ? <div style={{ maxWidth: "200px" }}>
                                            <p>Tên: {item.group.name}</p>
                                            <p>Đơn vị: {this.props.configData.levelGroup[item.group.level]}</p>
                                            { item.group.is_active == 0 ? <b className="text-danger">Nhóm mới</b> : ""}
                                        </div> : ""
                                    }
                                },
                                {
                                    title: "Trạng thái",
                                    index: "status",
                                    dataIndex: "status",
                                    render: (item) => {
                                        return item == 1 ? <span className="text-success">Đã duyệt</span> : item == 2 ? <span className="text-danger">Đã hủy</span> : <span className="text-warning">Đang chờ</span>
                                    }
                                },
                                {
                                    title: "Thông tin quản lý",
                                    render: (item) => {
                                        return <div style={{ maxWidth: "200px" }}>
                                            <p>Tên: {item.fullname}</p>
                                            <p>Chức vụ: {item.user_level}</p>
                                            <p>Phone: {item.phone}</p>
                                            <p>Facebook: {item.account_fb}</p>
                                            <p>Email: {item.email}</p>
                                        </div>
                                    }
                                },
                                {
                                    title: "Ghi chú",
                                    index: "note",
                                    dataIndex: "note",
                                    render: (item) => {
                                        return <Input.TextArea disabled defaultValue={item} style={{ minHeight: "100px" }} />
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    index: "created_time",
                                    render: (item) => {
                                        return moment(item.created_time).format('HH:mm DD-MM-YYYY')
                                    }
                                },
                                {
                                    title: "Hành động",
                                    index: "action",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Duyệt đơn">
                                                <Button disabled={item.status != 0} size="small" type="primary" onClick={() => {
                                                    this.setState({ visibleForm: true, selectedKeys: [item.group_id], expandedKeys: item.group ? [item.group.province_id, item.group.district_id, item.group.parent_id] : []}, () => {
                                                        setTimeout(() => {
                                                            if (this.formH) this.formH.setFieldsValue(item);
                                                        })
                                                    })
                                                }} className="mr-2"><i className="far fa-edit"></i></Button>
                                            </Tooltip>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" onClick={() => this.remove([item._id])}><i className="far fa-trash"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            rowKey="_id"
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
