"use strict";

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AccountMetaData, getCookie, setCookie, dimens } from '../../config/settings';
import { fetchData } from '../../lib/apis';

import MainAdminView from '../admin';
import { LoadingComponent } from '../../widgets';

export default class MainlayoutView extends Component{
  constructor(props){
    super(props);
    this.state = {
      UserLogin: null,
      title: 'Đăng bài fanpage',
      loadingMain: false
    };

    if (window.Notification && window.Notification.permission !== 'granted'){
      window.Notification.requestPermission();
    }
  }
  componentWillMount = () => {
    if(!AccountMetaData.checkIssetLogin()){
      AccountMetaData.logIn();
    } else{
      setTimeout(() => {
        this.getInfo();
      }, 1000)
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FB_ID,
        cookie: true,
        xfbml: true,
        version: 'v5.0'
      });
    }.bind(this);

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  getInfo = async () => {
    try{
      let response = await fetchData({
        url: 'api/v1/user/detail',
      });
      this.setState({
        UserLogin: response.user,
        loadingMain: true
      });
    } catch (err){
      if(typeof err == 'object' && err.code == 401){
        this.props.message({ content: 'Bạn không có quyền truy cập! Chuyển hướng sau 3s..', key: 'error', type: 'warning', duration: 3 });
        setTimeout(() => {
          AccountMetaData.logOut();
        }, 3000)
      } else{
        this.props.message({ content: 'Xin lỗi hệ thống tạm thời bị gián đoạn vui lòng thử lại sau!', key: 'message', type: 'warning', duration: 180 });
      }
    }
  }
  getUserInfo= async () => {
    try{
      let response = await fetchData({
        url: 'api/v1/user/detail',
      });
      if(response.user){
        this.setState({
          UserLogin: response.user
        })
      }
    } catch (err){
      console.log(err);
    }
  }
  handleLoading = (bol) => {
    this.setState({
      loadingMain: bol
    })
  }

  handleChecked = (e, slt) => {
    try {
      let table = e ? e.target.closest('table') : slt.closest('table');
      let name = e ? e.target.name : null;
      let items = table.querySelectorAll('[name="check_item"]:not(:disabled)');

      let num_checked = 0;
      items.forEach((item) => {
        if (item.checked) num_checked++;
      })
      if (name == 'checkall') {
        if (items.length == num_checked) {
          items.forEach((item) => {
            item.checked = false;
          })
          num_checked = 0;
        } else {
          items.forEach((item) => {
            item.checked = true;
          })
          num_checked = items.length;
        }
        
      }
      if (items.length == num_checked && num_checked > 0) {
        table.querySelector('[name="checkall"]').checked = true;
      } else {
        table.querySelector('[name="checkall"]').checked = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  parseMenu = (list) => {
    var map = {}, node, i;
    var roots = []

    for (i = 0; i < list.length; i++) {
        map[list[i].city_id] = i;
        list[i].children = [];
    }

    for (i = 0; i < list.length; i++) {
        node = list[i];
        node['title'] = node.name;
        node['key'] = node.city_id;
        node['value'] = node.city_id;
        node['isLeaf'] = node.level > 1 ? true : false;

        if (node.parent_id !== 0) {
            try {
                list[map[node.parent_id]].children.push(node);
            } catch (err) {
                console.log(node);
            }
        } else {
            roots.push(node);
        }
    }
    return roots;
  }
  
  render(){
    if(!this.state.loadingMain) return <LoadingComponent />;
    var props_m = {
      companys: this.state.companys,
      companySelected: this.state.companySelected,
      AccountMetaData: AccountMetaData,
      setCookie: setCookie,
      UserLogin: this.state.UserLogin,
      ...this.props,
      handleChecked: this.handleChecked,
      handleOffline: this.handleOffline,
      fetchData: fetchData,
      handleCompanySelected: (company) => this.setState({ companySelected: company}),
      getUserInfo: this.getUserInfo,
      selectCompany: this.selectCompany,
      parseMenu: this.parseMenu
    }
    // console.log(props_m);
    return(
      <div style={{width: "100%", height: "100%"}}>
          <Helmet>
            <title>
              {this.state.title}
            </title>
          </Helmet>
          <Switch>
            <Route
              path=''
              render={ props => <MainAdminView {...props_m} {...props}/> }
            />
        </Switch>
      </div>
    )
  }
}
