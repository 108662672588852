import React, { Component } from 'react';
import { Form, Input, Select, Button, Checkbox, Tree, Spin } from 'antd';

import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';

export default class TreeCity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            checkAll: false,
            listDataCity: []
        }
        this.total = 0;
    }
    componentWillMount() {
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let params = {};

            let res = await this.props.fetchData({
                url: `api/v1/geo/list?${qs.stringify(params)}`
            });

            this.setState({ listData: this.parseMenu(res.data), listDataCity: res.data, loading: false });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    parseMenu = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].city_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.name;
            node['key'] = node.city_id;
            node['value'] = node.city_id;
            node['isLeaf'] = node.level > 1 ? true : false;

            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                    this.total += 1;
                } catch (err) {
                    console.log(node);
                }
            } else {
                roots.push(node);
                this.total += 1;
            }
        }
        return roots;
    }

    render() {
        const loop = data =>
            data.map(item => {
                const title = <div className="d-flex align-items-center justify-content-between">
                    <span>{item.title}</span>
                    {
                        this.props.edit ?
                            <span className="d-flex align-items-center">
                                <a className="btn btn-sm btn-primary text-white" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({
                                        visibleForm: true
                                    }, () => {
                                        setTimeout(() => {
                                            if (this.formH) this.formH.setFieldsValue(item)
                                        })
                                    })
                                }}><i className="far fa-edit"></i></a>
                                {
                                    item.level <= 1 ?
                                        <a className="btn btn-sm btn-primary text-white ml-2" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.setState({
                                                visibleForm: true
                                            }, () => {
                                                setTimeout(() => {
                                                    if (this.formH) this.formH.setFieldsValue({
                                                        parent_id: item.city_id
                                                    })
                                                })
                                            })
                                        }}><i className="far fa-plus"></i></a> : null
                                }
                                <a className="btn btn-sm btn-danger text-white ml-2" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.remove([item.city_id]);
                                }}><i className="far fa-trash"></i></a>
                            </span> : null
                    }
                </div>;

                if (item.children) {
                    return { title, key: item.key, children: loop(item.children) };
                }

                return {
                    title,
                    key: item.key,
                };
            });
        return (
            <Spin spinning={this.state.loading}>
                {
                    this.props.checkall ?
                    <div style={{ paddingLeft: "10px", marginBottom: "10px"}}>
                        <Checkbox checked={this.state.checkAll} onChange={(e) => this.setState({ checkAll: e.target.checked}, () => {
                            this.setState({
                                checkedKeys: e.target.checked ? this.state.listDataCity.map((item) => item.city_id) : []
                            }, () => {
                                this.props.onCheck(this.state.checkedKeys);
                            })
                        })}>Chọn tất cả</Checkbox>
                    </div> : null
                }
                <Tree.DirectoryTree
                    treeData={loop(this.state.listData)}
                    showLine={true}
                    onExpand={(e) => {
                        console.log(e);
                        this.setState({ expandedKeys: e, autoExpandParent: false })
                    }}
                    checkable={this.props.checkable}
                    onCheck={(e, node) => {
                        let checked_child = e.checked;

                        let childrens = node.node.children;
                        while(childrens.length > 0){
                            let children = childrens.splice(0, 1);
                            childrens = childrens.concat(children[0].children);
                            if(node.checked == true && checked_child.indexOf(children[0].key) == -1){
                                checked_child.push(children[0].key);
                            } else if(node.checked == false && checked_child.indexOf(children[0].key) > -1){
                                checked_child.splice(checked_child.indexOf(children[0].key), 1)
                            }
                        }

                        this.setState({ checkAll: e.length == this.total, checkedKeys: checked_child});
                        if(this.props.onCheck) this.props.onCheck(checked_child, node);
                    }}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={this.state.autoExpandParent}
                    showIcon={false}
                    style={{ minHeight: "100px" }}
                    selectedKeys={this.props.selectedKeys || []}
                    onSelect={this.props.onSelect}
                    checkStrictly={true}
                    checkedKeys={this.state.checkedKeys}
                />
            </Spin>
        )
    }
}
