const settings = {}

export var dimens = {
    deviceWidth: window.innerWidth,
    hostDomain: domainSaveCookie(),
    name_auth: 'auth',
    name_company: 'company'
}

export const AccountMetaData = {
    token: (getCookie(dimens.name_auth)) ? getToken(getCookie(dimens.name_auth)) : '',
    logOut : () => {
        setCookie(dimens.name_auth, '', 0);
        setCookie(dimens.name_company, '', 0);
        window.location.href = `/logout`;
        return true;
    },
    logIn: () => {
        window.location.href = `/login`;
    },
    getToken: () => {
        try {
            return atob(getCookie(dimens.name_auth));
        } catch {
            return '';
        }
    },
    getCompany: () => {
        try {
            let company = getCookie(dimens.name_company);
            return company ? JSON.parse(company) : '';
        } catch {
            return '';
        }
    },
    setCookie: (value, days) => {
        var name = dimens.name_auth;
        var expires = "";
        if (days) {
        var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.hostDomain+"; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkIssetLogin: () => {
        if( !getCookie(dimens.name_auth)  ) {
            return false;
        }
        try{
          dimens['df_t'] = atob(getCookie(dimens.name_auth));
        } catch(err){
            return false
        }
        return true;
    }
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 1);
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + ";domain=.muabanhay.com; path=/";
    document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.hostDomain+"; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie(dimens.name_auth)  ) {
      return false;
  }
  try{
    dimens['df_t'] = atob(getCookie(dimens.name_auth));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}

export function domainSaveCookie(){
    try{
        let domain = "";
        let hostname = window.location.hostname;
        if(hostname.indexOf('.') > -1){
            let arr = hostname.split('.');
            domain = arr.splice(arr.length - 2, arr.length);
            domain = '.'+domain.join('.');
        } else {
            domain =  window.location.hostname;
        }
        return domain;
    } catch(err){
        return "";
    }
}

export default settings