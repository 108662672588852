import React, { Component } from 'react';
import { Dropdown, Menu } from 'antd';
import { AccountMetaData, setCookie, dimens } from '../config/settings';
import { Modal, Button, Tooltip, message, Pagination } from 'antd';
import config from '../lib/config';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {
         document.querySelector('.btn-bar').addEventListener("click", function(){
             if(document.querySelector('.sidebar').classList.contains("show")){
                document.querySelector('.sidebar').classList.remove('show');
             } else{
                document.querySelector('.sidebar').classList.add('show');
             }
         })
    }
    render() {
        let menu = (
            <Menu>
                <Menu.Item>
                    <a href="/me" rel="noopener noreferrer" target="_blank">
                        Cá nhân
                </a>
                </Menu.Item>
                <Menu.Item>
                    <a href="#" rel="noopener noreferrer" onClick={(e) => {
                        e.preventDefault();
                        AccountMetaData.logOut();
                    }}>
                        Đăng xuất
                </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="header">
                <div className="logo" onClick={() => window.location.reload()}>
                    <img src={'/logo.svg'} 
                        onError={(e) => e.target.src = require('../img/logo96.png')}
                    />
                    {/* <span>Vienmat</span> */}
                </div>
                <div className="header-root">
                    <div className="header-container header-top">
                        <div className="header-container-left">

                        </div>
                        <div className="header-container-right">
                            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                <div className="header-acc-name shadow">
                                    <div className="header-avatar">
                                        <img className="avatar" src={this.props.UserLogin.avatar ? this.props.UserLogin.avatar : require('../img/user.jpg')} />
                                    </div>
                                    {this.props.UserLogin.fullname}
                                </div>
                            </Dropdown>
                            <div className="btn-bar" style={{display: "none"}}>
                                <svg focusable="false" viewBox="0 0 24 24" role="presentation" fill="#000">
                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
