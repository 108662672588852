import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, Tree, Steps, Spin, Alert, Pagination, Tooltip, Select, Table } from 'antd';
import { fetchData } from '../../../lib/apis';
import { ExclamationCircleOutlined, FacebookFilled } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import { ModelGroupChannel, WidgetInputCity } from '../../../widgets';

export default class ManageChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            listDatacity: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount() {
        this.getListData();
        this.getListDataCity();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (options = {}) => {
        try {
            this.setState({ loading: true });
            let { type } = options;
            if (type == 'reset') this.page = 1;
            let params = {
                acc_type: 5,
                limit: this.limit,
                page: this.page
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/channel/list?${qs.stringify(params)}`
            });

            this.setState({
                listData: res.data,
                total: res.total,
                loading: false
            }, () => {
                this.props.handleChecked(null, document.querySelector('[name="checkall"]'));
            })
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    loginFb = () => {
        let _this = this;
        window.FB.login(function (response) {
            if (response.status == 'connected') {
                console.log(response);
                var key = 'updatable';
                message.loading({ content: 'Đang xử lý..', key });
                fetchData({
                    url: 'api/v1/channel/fanpagefb/add',
                    method: 'post',
                    data: { token: response.authResponse.accessToken }
                }).then((res) => {
                    message.success({ content: 'Thành công!', key, duration: 2 });
                    _this.page = 1;
                    _this.getListData({ type: 'reset' });
                }).catch((err) => {
                    message.warning({ content: 'Đã có lỗi xảy ra!', key, duration: 2 });
                })
            } else {
                // if(props.showNotiFy) props.showNotiFy('Đăng nhập không thành công!');
            }
        }, {
            // scope: 'public_profile,email,manage_pages,publish_pages,read_page_mailboxes,pages_messaging',
            scope: `public_profile,email,pages_messaging,pages_manage_engagement,pages_read_user_content,pages_read_engagement,pages_manage_metadata,pages_manage_posts`,
            return_scopes: true,
            enable_profile_selector: true,
            auth_type: 'rerequest'
        })
    }

    deleteMany = (options = {}) => {
        let { ids } = options;
        if (!ids || ids.length <= 0) {
            this.props.notification({
                title: "Xóa Fanpage",
                content: "Chọn ít nhất một đối tượng",
                type: "error"
            })
            return
        }
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/channel/delete/`,
                            method: 'delete',
                            data: { ids: ids }
                        })
                        _this.props.notification({
                            title: "Xóa Fanpage",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListData();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa Fanpage",
                            content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }

    componentWillUnmount = () => {

    }
    update = async (values) => {
        try {
            this.setState({ loadingEdit: true });
            await fetchData({
                url: `api/v1/channel/${values['_id']}`,
                method: 'put',
                data: values
            })
            this.setState({ loadingEdit: false, visibleFormEdit: false });
            this.getListData();
            this.props.notification({
                title: "Cập nhập",
                content: 'Thao tác thành công!'
            })
        } catch (err) {
            this.setState({ loadingEdit: false });
            this.props.notification({
                title: "Cập nhập",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    syncFanpage = () => {
        if (this.props.UserLogin.is_test == 1) {

        } else {
            // this.loginFb();
        }
    }

    getListDataCity = async () => {
        try {
            this.setState({ loadingCity: true });
            let params = {};

            let res = await this.props.fetchData({
                url: `api/v1/geo/list?${qs.stringify(params)}`
            });

            this.setState({ listDatacity: this.props.parseMenu(res.data), loadingCity: false });
        } catch (err) {
            this.setState({ loadingCity: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <ModelGroupChannel visible={this.state.visibleModalGroupChannel} hide={() => this.setState({ visibleModalGroupChannel: false })}
                    data={this.state.channelGroup} create={this.createGroupChannel} delete={this.deleteGroupChannel} showEdit={this.showEditGroupChannel}
                    save={this.saveGroupChannel}
                />

                <Modal
                    title={'Chỉnh sửa'}
                    visible={this.state.visibleFormEdit}
                    onCancel={() => this.setState({ visibleFormEdit: null })}
                    footer={null}
                    className="modal-form-h"
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formEdit = evt}
                        onFinish={this.update}
                        initialValues={{
                            alias: ""
                        }}
                    >
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="group_id" noStyle>
                            <Input type="hidden"/>
                        </Form.Item>
                        <Form.Item label="Chọn nhóm">
                            <div style={{maxHeight: "300px", overflow: "auto"}}>
                                <Spin spinning={this.state.loadingCity}>
                                    <Tree.DirectoryTree
                                        treeData={this.state.listDatacity}
                                        showLine={true}
                                        onExpand={(e) => {
                                            this.setState({ expandedKeys: e, autoExpandParent: false })
                                        }}
                                        expandedKeys={!this.state.loadingCity ? this.state.expandedKeys : []}
                                        defaultExpandedKeys={this.state.expandedKeys}
                                        autoExpandParent={this.state.autoExpandParent}
                                        showIcon={false}
                                        style={{ minHeight: "100px" }}
                                        selectedKeys={this.state.selectedKeys}
                                        onSelect={(e) => this.setState({ selectedKeys: e }, () => {
                                            this.formEdit.setFieldsValue({ group_id: e[0] })
                                        })}
                                    />
                                </Spin>
                            </div>
                        </Form.Item>
                        <div style={{ textAlign: "right" }} className="mt-4">
                            <Button onClick={() => this.setState({ visibleFormEdit: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingEdit}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý fanpage: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <a className="btn btn-primary text-white mr-2 d-flex align-items-center" onClick={this.loginFb}><FacebookFilled style={{ marginRight: "5px", fontSize: "20px" }} /> Login with Facebook</a>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa nhiều'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => {
                                    this.deleteMany({
                                        ids: this.state.dataSelected
                                    })
                                }}><i className="far fa-trash"></i></a>
                            </Tooltip>
                            {/* <Tooltip title={'Quản lý nhóm'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleModalGroupChannel: true })}><i className="far fa-users"></i></a>
                            </Tooltip> */}
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                            }}
                        >
                            <Form.Item name="group_id" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="keyword">
                                        <Input placeholder="Nhập tên fanpage" onChange={(e) => {
                                            if (this.timeSearch) clearTimeout(this.timeSearch);
                                            this.timeSearch = setTimeout(() => {
                                                this.getListData({ type: 'reset' });
                                            }, 300);
                                        }} />
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <WidgetInputCity {...this.props} onSelect={(e) => {
                                        this.formFilter.setFieldsValue({ "group_id": e });
                                        this.getListData({ type: 'reset' });
                                    }} setValueFields={(values) => this.formFilter.setFieldsValue(values)} col="col-md-4"/>
                                </div>
                                <div className="col-md-1">
                                    <Button onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData({ type: 'reset' });
                                    }} type="primary">Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table
                            columns={[
                                {
                                    title: "STT",
                                    render: (item, value, index) => {
                                        return (this.limit * this.page) - this.limit + index + 1
                                    }
                                },
                                {
                                    title: "Ảnh",
                                    index: "avatar",
                                    render: (item) => {
                                        return <div style={{ width: "35px", height: "35px" }}>
                                            {
                                                item.avatar ? <img className="avatar" src={item.avatar} /> : <img className="avatar" src={require('../../../img/user.jpg')} />
                                            }
                                        </div>
                                    }
                                },
                                {
                                    title: "Id",
                                    index: "user_id",
                                    dataIndex: "user_id"
                                },
                                {
                                    title: "Tên",
                                    index: "fullname",
                                    dataIndex: "full_name"
                                },
                                {
                                    title: "Nhóm",
                                    index: "group",
                                    render: (item) => {
                                        return item.group ? item.group.name : ""
                                    }
                                },
                                {
                                    title: "Ngày thêm",
                                    index: "created_time",
                                    render: (item) => moment(item.created_time).format('HH:mm DD-MM-YYYY')
                                },
                                {
                                    title: "Hành động",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" className="mr-2" onClick={() => this.deleteMany({ ids: [item._id] })}><i className="far fa-trash-alt"></i></Button>
                                            </Tooltip>
                                            <Tooltip title="Sửa">
                                                <Button size="small" type="primary" onClick={() => {
                                                    this.setState({ visibleFormEdit: true, selectedKeys: [item.group_id], expandedKeys: item.group ? [item.group.province_id, item.group.district_id, item.group.parent_id] : []}, () => {
                                                        setTimeout(() => {
                                                            if (this.formEdit) this.formEdit.setFieldsValue(item);
                                                        })
                                                    })
                                                }}><i className="far fa-edit"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            rowKey="_id"
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
