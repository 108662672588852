import React, { Component } from 'react';
import { Statistic, Card } from 'antd';
import moment from 'moment';

export default class StatitiscalView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }
    componentWillMount() {
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let res = await this.props.fetchData({
                url: `api/v1/statitiscal`
            })
            this.setState({
                loading: false,
                province: res.data.find((item) => item.level == 0),
                district: res.data.find((item) => item.level == 1),
                commune: res.data.find((item) => item.level == 2)
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Thống kê",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    render() {
        console.log(this.state);
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <h3>Tổng số fanpage</h3>
                    <div className="row">
                        <div className="col-md-4">
                            <Card>
                                <Statistic title="Fanpage cấp Tỉnh/TP" value={this.state.province ? this.state.province.total : 0 }/>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <Statistic title="Fanpage cấp Quận/Huyện" value={this.state.district ? this.state.district.total : 0 }/>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <Statistic title="Fanpage cấp Xã/Phường" value={this.state.commune ? this.state.commune.total : 0 }/>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
