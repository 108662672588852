import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Radio, Table, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert, Upload, Checkbox } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import { WidgetInputCity } from '../../../widgets';

const { Option } = Select;

export default class AutoReplyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listProvinces: [],
            listDistricts: [],
            listCommunes: [],
            listData: [],
            total: 0,
            loading: false,
            fileList: [],
            selected_level_city: false
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/group/channel?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    remove = async (ids) => {
        let _this = this;
        try {
            if (!ids || ids.length == 0) throw ({ message: "Chọn ít nhất 1 nhóm để xóa" })
            Modal.confirm({
                title: 'Bạn có chắc muốn xóa',
                icon: <ExclamationCircleOutlined />,
                okText: 'Tiếp tục',
                okType: 'danger',
                cancelText: 'Hủy',
                className: "modal-confirm-h",
                onOk() {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let res = await fetchData({
                                url: `api/v1/group/channel`,
                                method: 'delete',
                                data: {
                                    ids: ids
                                }
                            });
                            _this.getListData();
                            _this.props.notification({
                                content: 'Thao tác thành công!',
                                title: 'Xóa',
                                type: 'success'
                            })
                            resolve();
                        } catch (err) {
                            _this.props.notification({
                                content: err.message || 'Đã có lỗi xảy ra',
                                title: 'Xóa',
                                type: 'error'
                            })
                            reject();
                        }
                    })
                },
                onCancel() { },
            });
        } catch (err) {
            _this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xóa',
                type: 'error'
            })
        }
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });

            values['city_id'] = values['communes'] || values['districts'] || values['provinces'];

            if (!values['city_id'] && !values['grassroots_text']) throw ({ message: 'chọn ít nhất 1 nhóm quản lý' });

            values['level'] = values['grassroots_text'] ? 3 : values['communes'] ? 2 : values['districts'] ? 1 : 0;

            values['city_ids'] = [];

            if(values['provinces']) values['city_ids'].push(values['provinces']);
            if(values['districts']) values['city_ids'].push(values['districts']);
            if(values['communes']) values['city_ids'].push(values['communes']);

            let url = `api/v1/group/channel`;
            let method = 'post';

            if (values['_id']) {
                url = `api/v1/group/channel/${values['_id']}`;
                method = 'put';
            }

            await fetchData({
                url: url,
                data: values,
                method: method
            });

            this.getListData();
            this.formH.resetFields();
            this.setState({ loadingForm: false, visibleForm: values['_id'] ? this.state.visibleForm : false });

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập nhóm',
                type: 'success'
            })
        } catch (err) {
            console.log(err);
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập nhóm',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <Modal
                        title={'Cập nhập nhóm fanpage'}
                        visible={this.state.visibleForm}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false }, () => this.formH.resetFields())}
                        width={900}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            className="form_type_1"
                            initialValues={{

                            }}
                            onFinish={this.save}
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Item name="_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item
                                        name="name"
                                        label="Tên nhóm"
                                        rules={[{ required: true, message: 'Tên nhóm không được để trống' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Cấp (nhóm quản lý)">
                                        <WidgetInputCity {...this.props} grassrootsInput={true} visibleLabel={true} setValueFields={(values) => this.formH.setFieldsValue(values)}/>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => this.setState({ visibleForm: false })}>
                                    Đóng
                                </Button>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý nhóm: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true }, () => {
                                    if (this.formH) this.formH.resetFields();
                                })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => {
                                    this.remove(this.state.dataSelected)
                                }}><i className="far fa-trash"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                        >
                            <Form.Item name="city_id" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="name">
                                        <Input placeholder="Nhóm cần tìm" onChange={(e) => {
                                            if (this.timeSearch) clearTimeout(this.timeSearch);
                                            this.timeSearch = setTimeout(() => {
                                                this.getListData({ type: 'reset' });
                                            }, 300);
                                        }} />
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <WidgetInputCity {...this.props} grassrootsSelect={true} onSelect={(e) => {
                                        this.formFilter.setFieldsValue({ "city_id": e });
                                        this.getListData({ type: 'reset' });
                                    }} setValueFields={(values) => this.formFilter.setFieldsValue(values)}/>
                                </div>
                                <div className="col-md-1">
                                    <Button onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData({ type: 'reset' });
                                    }} type="primary">Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table
                            columns={[
                                {
                                    title: "STT",
                                    render: (item, value, index) => {
                                        return (this.limit * this.page) - this.limit + index + 1
                                    }
                                },
                                {
                                    title: "Tên nhóm",
                                    index: "name",
                                    dataIndex: "name"
                                },
                                {
                                    title: "Cấp",
                                    index: "level",
                                    render: (item) => {
                                        return this.props.configData.levelGroup[item.level]
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    index: "created_time",
                                    render: (item) => {
                                        return moment(item.created_time).format('HH:mm DD-MM-YYYY')
                                    }
                                },
                                {
                                    title: "Hành động",
                                    index: "action",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" onClick={() => this.remove([item._id])}><i className="far fa-trash"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            rowKey="_id"
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.dataSelected,
                                onChange: (values) => {
                                    this.setState({
                                        dataSelected: values
                                    })
                                }
                            }}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
