import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Radio, Table, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert, Upload, Checkbox, Tree } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined, FormOutlined, FolderFilled } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import { WidgetInputCity } from '../../../widgets';

const { Option } = Select;

export default class AutoReplyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listProvinces: [],
            listDistricts: [],
            listCommunes: [],
            listData: [],
            total: 0,
            loading: false,
            fileList: [],
            selected_level_city: false,
            searchValue: ""
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    componentDidMount = () => {

    }

    getListData = async () => {
        try {
            this.setState({ loading: true });
            let params = {};

            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/geo/list?${qs.stringify(params)}`
            });

            this.setState({ listData: this.parseMenu(res.data), loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    remove = async (ids) => {
        let _this = this;
        try {
            if (!ids || ids.length == 0) throw ({ message: "Chọn ít nhất 1 nhóm để xóa" })
            Modal.confirm({
                title: 'Bạn có chắc muốn xóa',
                icon: <ExclamationCircleOutlined />,
                okText: 'Tiếp tục',
                okType: 'danger',
                cancelText: 'Hủy',
                className: "modal-confirm-h",
                onOk() {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let res = await fetchData({
                                url: `api/v1/geo`,
                                method: 'delete',
                                data: {
                                    ids: ids
                                }
                            });
                            _this.getListData();
                            _this.props.notification({
                                content: 'Thao tác thành công!',
                                title: 'Xóa',
                                type: 'success'
                            })
                            resolve();
                        } catch (err) {
                            _this.props.notification({
                                content: err.message || 'Đã có lỗi xảy ra',
                                title: 'Xóa',
                                type: 'error'
                            })
                            reject();
                        }
                    })
                },
                onCancel() { },
            });
        } catch (err) {
            _this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xóa',
                type: 'error'
            })
        }
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });

            let url = `api/v1/geo/create`;
            let method = 'post';

            if (values['_id']) {
                url = `api/v1/geo/${values['_id']}`;
                method = 'put';
            }

            await fetchData({
                url: url,
                data: values,
                method: method
            });

            this.getListData();
            this.formH.resetFields();
            this.setState({ loadingForm: false, visibleForm: false });

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập nhóm',
                type: 'success'
            })
        } catch (err) {
            console.log(err);
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập nhóm',
                type: 'error'
            })
        }
    }

    updateTreeData = (list, key, children) => {
        return list.map(node => {
            if (node.key === key) {
                return {
                    ...node,
                    children,
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: this.updateTreeData(node.children, key, children),
                };
            }
            return node;
        });
    }

    parseTreeData = (list) => {
        return list.map((item) => {
            return {
                title: item['name'],
                value: item['city_id'],
                children: [],
                isLeaf: item.level <= 1 ? false : true
            }
        });
    }

    parseMenu = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].city_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.name;
            node['key'] = node.city_id;
            node['value'] = node.city_id;
            node['isLeaf'] = node.level > 1 ? true : false;

            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    console.log(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    searchData = e => {
        const { value } = e.target;
        this.setState({ searchValue: value })
    };

    render() {
        const loop = data =>
            data.map(item => {
                const title = <div className="d-flex align-items-center justify-content-between">
                    <span>{item.title}</span>
                    <span className="d-flex align-items-center">
                        <a className="btn btn-sm btn-primary text-white" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                                visibleForm: true
                            }, () => {
                                setTimeout(() => {
                                    if(this.formH) this.formH.setFieldsValue(item)
                                })
                            })
                        }}><i className="far fa-edit"></i></a>
                        {
                            item.level <= 1 ?
                            <a className="btn btn-sm btn-primary text-white ml-2" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({
                                    visibleForm: true
                                }, () => {
                                    setTimeout(() => {
                                        if(this.formH) this.formH.setFieldsValue({
                                            parent_id: item.city_id
                                        })
                                    })
                                })
                            }}><i className="far fa-plus"></i></a> : null
                        }
                        <a className="btn btn-sm btn-danger text-white ml-2" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.remove([item.city_id]);
                        }}><i className="far fa-trash"></i></a>
                    </span>
                </div>;

                if (item.children) {
                    return { title, key: item.key, children: loop(item.children) };
                }

                return {
                    title,
                    key: item.key,
                };
            });
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <Modal
                        title={'Cập nhập nhóm'}
                        visible={this.state.visibleForm}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false }, () => this.formH.resetFields())}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            className="form_type_1"
                            initialValues={{
                                parent_id: 0
                            }}
                            onFinish={this.save}
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Item name="_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item name="parent_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item
                                        name="name"
                                        label="Tên nhóm"
                                        rules={[{ required: true, message: 'Tên nhóm không được để trống' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => this.setState({ visibleForm: false })}>
                                    Đóng
                                </Button>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý nhóm</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true }, () => {
                                    if (this.formH) this.formH.resetFields();
                                })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => {
                                    this.remove(this.state.dataSelected)
                                }}><i className="far fa-trash"></i></a>
                            </Tooltip>
                        </div>
                        {/* <Form
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="name">
                                        <Input placeholder="Nhóm cần tìm" onChange={this.searchData} value={this.state.searchValue}/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-1">
                                    <Button onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData({ type: 'reset' });
                                    }} type="primary">Xóa lọc</Button>
                                </div>
                            </div>
                        </Form> */}
                    </div>
                    <div style={{ minHeight: "100%" }}>
                        <Spin spinning={this.state.loading}>
                            <Tree.DirectoryTree
                                treeData={loop(this.state.listData)}
                                showLine={true}
                                onExpand={(e) => {
                                    console.log('expand', e)
                                    this.setState({ expandedKeys: e, autoExpandParent: false })
                                }}
                                checkable
                                onSelect={(e) => {
                                    console.log('select', e)
                                    // this.setState({ valueTree: e})
                                }}
                                onCheck={(e) => this.setState({ dataSelected: e })}
                                expandedKeys={this.state.expandedKeys}
                                // selectedKeys={this.state.valueTree}
                                autoExpandParent={this.state.autoExpandParent}
                                showIcon={false}
                                style={{minHeight: "100px"}}
                            />
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
