import {AccountMetaData} from  '../config/settings';

export const apis = {
    host: process.env.REACT_APP_API_CHOTSALE_URL+'/api/v1',
    api_key : process.env.REACT_APP_API_CHOTSALE_KEY,
    post_mes: {
        inbox: process.env.REACT_APP_API_INSTAGRAM_SV+'/InboxController.php',
        reply: process.env.REACT_APP_API_INSTAGRAM_SV+'/ReplyController.php',
        sendNew: process.env.REACT_APP_API_INSTAGRAM_SV+'/InboxNewUserController.php',
        MarkSeen: process.env.REACT_APP_API_INSTAGRAM_SV+'/MarkSeen.php',
        markSeenAll: process.env.REACT_APP_API_INSTAGRAM_SV+'/MarkSeenAll.php',
        DeleteDirect: process.env.REACT_APP_API_INSTAGRAM_SV+'/DeleteDirect.php',
        DeleteComment: process.env.REACT_APP_API_INSTAGRAM_SV+'/DeleteComment.php',
        ConvertCode: process.env.REACT_APP_API_INSTAGRAM_SV+'/ConvertCode.php',
    }
}

export function fetchData(data = {}){
    let url = `${process.env.REACT_APP_API_CHOTSALE_URL}/${data['url']}`;
    let options = {
        method: data.method ? data.method : 'GET',
        headers: {
            Authorization: `${AccountMetaData.getToken()}`
        }
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = data.data;
    }

    if(data.type_post != 'upload_image'){
        options['headers']['Content-Type'] = 'application/json';
        options['body'] = JSON.stringify(options['body']);
    }
    
    return new Promise((resolve, reject) => {
        fetch(url, {
            withCredentials: true,
            credentials: 'include',
            crossDomain: true,
            ...options
        })
        .then(response =>  {
            // console.log('response');
            if(data['type_api']== 2){
                return response.text().then(function(text) {
                    try {
                        let result = text ? JSON.parse(text) : {
                            status: 404,
                            message: 'Đã có lỗi xảy ra, vui lòng thử lại sau!'
                        };
                        return result;
                    } catch (err) {
                        console.log(err);
                        return {
                            status_code: 500,
                            message: 'Da co loi'
                        };
                    }
                })
            } else{
                return response.json();
            }
        })
        .then((response) => {
            if(response.code && response.code != 200 || response.status_code && response.status_code != 200) return reject(response);
            return resolve(response);
        }).catch((err) => {
            return reject({
                code: 500,
                err: err
            });
        })
    })
}